import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getReportsFields = () => {
    return http.get("/api/getfld", { headers: { 'Authorization': 'Bearer ' + token } });
};

const getAllReports = (baps) => {
    return http.get(`/api/rdinfo?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token } });
};

const getReportById = (baps, bid) => {
    return http.get(`/api/rdinfobid?baps=${baps}&bid=${bid}`, { headers: { 'Authorization': 'Bearer ' + token } });
};

const addReportOverview = (data) => {
    return http.post(`/api/insrinfo`, data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } });
};

const getCourseEnrollments = (baps) => {
    return http.get(`/api/stdcorsreport?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token } });
};

const getActivityResults = (baps) => {
    return http.get(`/api/stdresrepo?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token } });
};


const ReportService = {
    getReportsFields,
    getAllReports,
    addReportOverview,
    getReportById,
    getCourseEnrollments,
    getActivityResults
};

export default ReportService;